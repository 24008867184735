.App {
  height: 100%;
  overflow: hidden;
}

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 9pt;
}

.axis {
  color: grey
}

h1 {
  font-size: 18pt;
}
h2 {
  font-size: 12pt;
}

h3 {
  font-size: 10pt;
}

.ship-list-label {
  padding-right: 8px;
}

input {
  color:white;
  font-family: "Sono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "MONO" 1;  
}
.mainscreen-container {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-start;
  height: 100vh;
  width: 100vw;
  color: white;
}

.spaceview-canvas {
  position: absolute;
  background-color:black;
  color-scheme: dark;
  z-index: 1;
}

.controls-pane {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100vh;
  padding-right: 4px;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 250px;
  border: 1px solid #b6bcae;
  background-color: black;
  color-scheme: dark;
  overflow: hidden auto;
}

.control-input {
  margin: 2px;
  padding: 2px;
  width: 50px; 
  height: 30px;
  border-radius: 5px;
  color: black;
  background-color: whitesmoke;
  border: 1px solid #333;
  font-size: 9pt;
}

.design-input {
  width: 150px;
}

.standoff-input {
  width: 100px;
}
.control-launch-div {
  display: flex;
  align-items: center;
}

.control-button {
  height: 30px;
  width: 100%
}

.control-launch-button {
  width: fit-content;
  height: 30;
}
.coordinate-input {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  height: unset
}

/* This isn't working as I'd like */
.control-name-input {
  width: 100%;
  height: 30px;
  background-color:#eeebeb;
  color: black;
}

.control-label {
  display: flex;
  flex-direction: column;
  font-size:12pt;
  margin: 0px;
  padding: 2px;
  color: white;
  height: unset
}

.control-label.h2 {
  font-size: 12pt;
  font-weight: bold;
}

.as-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: black;
}

.as-input-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  color: black;
}
.as-label {
  color: white;
  font-size: 12pt;
  margin: 2px;
}

.clickable-label {
  cursor: pointer;
}

.as-input {
  color: black;
  background-color: #eeebeb;
  border: 1px solid #333;
  height: 24px;
  width: 30px;
}

.blue-button {
  background-image: linear-gradient(#42A1EC, #0070C9);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  letter-spacing: -.022em;
  line-height: 1.47059;
  overflow: visible;
  margin: 2px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 12pt;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

.blue-button:disabled {
  cursor: default;
  opacity: .3;
}

.blue-button:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.blue-button:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}

.blue-button:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}

.orbitron-special {
  font-family: "Orbitron", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.sono-regular {
  font-family: "Sono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "MONO" 1;
}

.plan-accel-text {
  font-family: "Sono", monospace;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 10pt;
  font-variation-settings:
    "MONO" 1;

}
.ship-info-window {
  position: fixed;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.85);
  right: 0;
  width: 400px;
  border: 3px solid #b6bcae;
  z-index: 10;
  color: whitesmoke;
}

.computer-window {
  position: relative;
  top: 0;
  left: 0px;
  padding-right: 4px;
  border: 1px solid #b6bcae;
  z-index: 10;
  overflow: auto;
}

.view-controls-window {
  position: fixed;
  top: 0;
  right: 0px;
  padding-left: 4px;
  border: 1px solid #b6bcae;
  z-index: 12;
}

.control-form {
  justify-content: space-between;
  padding-top: 6px;
  padding-left: 2px;
  padding-right: 6px;
}

.vital-stats-bloc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.target-entry-form {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  padding-left: 2px;
}

.target-specifics-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.target-details-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.select-dropdown {
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.beam-type-icon {
  width: 20px;
  height: 20px;
  fill: red;
}

.pulse-type-icon {
  width: 20px;
  height: 20px;
  fill: rgb(0, 140, 255);
}
.missile-type-icon {
  width: 20px;
  height: 20px;
  fill: green;
}

.info-icon {
  font-size: 9pt;
}

.tooltip-body {
  z-index: 100;
  padding: 2px !important;
  color: #3dfc32 !important;
  font-size: 2pt;
}

#design-tooltip {
  z-index: 100;
  color: #fff;
  background-color: rgb(55, 55, 55);
  font-size: 8pt;
  padding: 2px;
  padding-left: 6px;
  
}

.label-with-tooltip {
  flex-direction: row;
}

.weapon-symbol {
  background-color:transparent;
  width: 40px;
  height: auto;
  grid-column: 1;
  grid-row: 1;
  align-self: flex-end;
}

.barbette-button {
  bottom: 0;
}

.weapon-symbol-count {
  background-color:transparent;
  font-size: 10pt;
  font-weight: bold;
  color: white;
  z-index: 3;
  grid-column: 1;
  grid-row: 1;
}

.weapon-list {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
}

.weapon-button {
  display: flex;
  background-image: linear-gradient(#b0b0b0, #525253);
  border: 1px solid #545454;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  letter-spacing: -.022em;
  line-height: 1.47059;
  overflow: visible;
  margin: 2px;
  text-align: center;
  vertical-align: baseline;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 12pt;
  height: 35px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

 }

 .weapon-button-tooltip {
  padding-left: 6px !important;
 }

 .weapon-button:disabled {
  cursor: default;
  opacity: .3;
}

.weapon-button:hover {
  background-image: linear-gradient(#d0d0d0, #727272);
  border-color: #777777;
  text-decoration: none;
}

.weapon-button:active {
  background-image: linear-gradient(#a0a0a0, #424243);
  border-color: #646464;
  outline: none;
}

.weapon-button:focus {
  box-shadow: rgba(131, 192, 253, 0.5) 0 0 0 3px;
  outline: none;
}

 .button-next-round {
  margin-top: auto
}

.stats-bloc-entry {
  padding-right: 4px;
}

.authentication-container {
  background-image: url("../public/assets/galaxy1.png");
  background-size: cover;
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: black;
  color: white;
  font-family: "Orbitron", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.authentication-title {
  font-size: 48pt;
  background-color: transparent
}

.admin-button-window {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0px;
  padding-left: 4px;
  border: 0px solid #b6bcae;
  z-index: 5;
}

.user-list {
  padding-right: 0px;
  border: 1px solid #b6bcae;
  overflow: auto;
  padding-inline-start: 0px;
  
}

.user-list-list {
  padding-inline-start: 15px;
  li::marker {
    color: rgb(0, 255, 0);
  }
}
.logout-button {
  width: 100%
}

.authentication-blurb {
  font-size: 12pt;
  width: 40%
}

.ship-design-description-tooltip {
  max-width: 250px
}

.crew-builder-window {
  top: 0;
  left: 0px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
}

.crew-builder-input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.crew-builder-gun-selector {
  width: 40px;
}

.chevron {
  transition: transform .2s ease-in-out;
}

.chevron.closed {
  transform: rotate(0deg);
}

.chevron.open {
  transform: rotate(180deg);
}

.accordion-header {
  font-size: 14pt;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 0px;
  padding-right: 4px;
}

#results-window {
  overflow: auto;
}

.crew-actions-form-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  justify-items: left;
  align-items: center;
}

.tutorial-instruction-text {
  color: red;
}

.fire-actions-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.called-shot-menu {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  color: black;
  background-color: whitesmoke;
  border: 1px solid #333;
  font-family: 'Orbitron', sans-serif;
  font-size: 9pt;
}

.sensor-action-select {
  width: 100%;
  margin: 2px;
  padding: 2px;
}